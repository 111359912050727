<template>
  <div class="chatbot" id="ChatBot" @mouseenter="chatbotOpen=1" @mouseleave="chatbotOpen=0">
    <div ref="chatbotRef" @click.stop="botShow = !botShow">
      <img src="@/assets/chatbot.gif" alt="" class="chatbotImg" v-if="chatbotOpen == 0"/>
      <img src="@/assets/chatbot_open.gif" alt="" class="chatbotOpenImg" v-else/>
    </div>
  </div>
  <div id="chatBotDialog" class="chatBotDialog" ref="chatBotRef" v-show="botShow">
    <header>
      <h2>YKnow自助答疑</h2>
      <h3>快速解答从开户到投放过程中遇到的常见问题</h3>
      <i class="miniBtn iconfont icon-ylmini" @click.stop="chatBotDialogMini"></i>
      <i class="closeBtn el-icon-close" @click.stop="chatBotDialogClose"></i>
    </header>

    <section class="dialogBox">
      <el-scrollbar height="342px" ref="scrollbarRef">
        <a href="javascript:;" class="record" @click="getRecord(1)">点击查看历史咨询记录</a>
        <div class="bot">
          <div class="botDialog">
            <p class="Text">
              您好,{{
                userInfo.name
              }}。欢迎使用YKnow自主答疑功能。您可以点击下面选项查找您的问题，也可以输入“人工”接入人工服务，让我更好的回答您的问题吧。
            </p>
          </div>
        </div>
      </el-scrollbar>
    </section>

    <footer id="footerDriver">
      <div id="searchDriver">
        <div class="proList" id="proListDriver">
          <!-- Swiper -->
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="p in problem" :key="p.id" @click="getProList(p)">
                <span>{{ p.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="search">
          <el-row align="middle">
            <el-col :span="19">
              <el-input
                  size="small"
                  :placeholder="placeholderBot"
                  v-model.trim="botProblem"
                  clearable
                  @keyup.enter="sendPro()"
                  id="WT"
              ></el-input>
            </el-col>
            <el-col :span="5" style="text-align: right">
              <el-button id="send" size="small" type="primary" @click="sendPro">发送</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </footer>

    <!-- 新用户YKnow引导 -->
    <div class="UserGuideWrap" v-show="this.guideWrapShow == 0"></div>
    <el-tooltip placement="left" effect="light" :manual="true" v-model="tipShow1">
      <template #content>
        <p class="Text">YKonw使用说明(1/3)</p>
        <p class="Text" style="max-width: 250px">这里是YKnow自助答疑，可以快速解答开户到投放过程中遇到的常见问题。</p>
        <div style="text-align: right; margin: 0">
          <el-button type="text" size="mini" @click="skip">跳过</el-button>
          <el-button type="primary" size="mini" @click="firstStep">下一步</el-button>
        </div>
      </template>
      <div class="GuideRange1" v-show="tipShow1"></div>
    </el-tooltip>

    <el-tooltip placement="left" effect="light" :manual="true" v-model="tipShow2">
      <template #content>
        <p class="Text">YKonw使用说明(2/3)</p>
        <p class="Text" style="max-width: 250px">
          点击输入框上方的关键词标签，或者输入您想要提问的内容并发送，就可以快速找到答案。
        </p>
        <div style="text-align: right; margin: 0">
          <el-button type="text" size="mini" @click="skip">跳过</el-button>
          <el-button type="primary" size="mini" @click="secondStep">下一步</el-button>
        </div>
      </template>
      <div class="GuideRange2" v-show="tipShow2">
        <img src="../../assets/Yknow.png" alt=""/>
      </div>
    </el-tooltip>

    <el-tooltip placement="left" effect="light" :manual="true" v-model="tipShow3">
      <template #content>
        <p class="Text">YKonw使用说明(3/3)</p>
        <p class="Text" style="max-width: 250px">体验一下，即可完成新手任务！</p>
        <div style="text-align: right; margin: 0">
          <el-button type="primary" size="mini" @click="doneStep">完成</el-button>
        </div>
      </template>
      <div class="GuideRange3" v-show="tipShow3">
        <img src="../../assets/Yknow.png" alt=""/>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper'
import $ from 'jquery'
import base from '../../request/base'
import {h} from "vue";

export default {
  name: 'ChatBot',
  components: {Swiper},
  data() {
    return {
      botShow: false,
      chatbotOpen: 0,
      base: base,
      client_list: [],
      client_id: '',
      infoList: [],
      userInfo: '',
      botProblem: '',
      problem: [],
      answer: [],
      placeholderBot: '点此输入您要问的问题...',
      placeholderPeople: '很高兴为您服务，请输入您的问题',
      // 新手引导
      guideWrapShow: 1,
      tipShow1: false,
      tipShow2: false,
      tipShow3: false,
      queueUser: 0,
      isOnline: 0,
      queneNum: 0,
      queneCode: 0,
      // 打分
      rateValue: 0,
      isCutdown: 0,
      thirdCut: 0,
      thirdClock: null,
      fiveClock: null,
      freeClock: null,
      // autoCloseClock: null,
      rateRead: 0,
      imgLoading: false,
      isManual: false,
      isCancel: 0,
      isAuto: 0,
      taskNum: 0,
      // 对话框展示的粘贴图片
      pasteShow: false,
      pasteFile: '',
      pasteFilePath: '',
      filePathDialog: '',
      filePathList: [],
      isArtificial: false,
    }
  },
  watch: {
    botShow(n, o) {
      if (this.isArtificial) {
        this.openMeiQia()
      } else {
        this.closeMeiQia()
      }
    },
  },
  mounted() {
    // 获取localStorage中的用户信息
    let user = window.localStorage.getItem('userInfo')
    if (!user || user == 'undefined') {
      this.userInfo = {}
    } else {
      this.userInfo = JSON.parse(user)
    }
    let swiper = new Swiper('.mySwiper', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
    this.getCate()
    window.getRecord = this.getRecord
    this.$bus.on('getChatBotSteps', this.getChatBotSteps)
    this.$bus.on('getAutoLink', this.getAutoLink)

    if (!_MEIQIA) return
    _MEIQIA('beforeCloseWindow', () => {
      this.isArtificial = false
      this.botShow = true
    });

    _MEIQIA('endConversation', () => {
      this.isArtificial = false
      this.botShow = true
    })

    // _MEIQIA('convClickCallback', (val) => {
    //   console.log(val)
    //   this.botShow = false
    // })
  },
  methods: {
    getHref(content) {
      if (!content) {
        return ''
      }
      const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g
      content = content.replace(urlPattern, match => {
        const urlHttp = match.indexOf('http://') && match.indexOf('https://')
        const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2]
        const href = urlHttp === -1 ? `https://${match}` : match
        return `<a id="urlA" target="_blank" href="${href}">${href}</a>`
      })
      return content
    },
    // 获取分类信息
    async getCate() {
      const {data: res} = await this.$http.post('cloudfb/document')
      this.problem = res.data
      this.taskNum = res.num
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
      })
    },
    async openMeiQia() {
      if (!_MEIQIA) return
      _MEIQIA('showPanel')
    },
    async closeMeiQia() {
      if (!_MEIQIA) return
      _MEIQIA('closePanel')
    },
    async sendPro() {
      console.log(document, _MEIQIA)
      if (this.botProblem == '') {
        return false
      }

      if (this.botProblem.indexOf('人工') > -1) {
        $('.dialogBox .el-scrollbar__view').append(`<div class="user"><div class="userDialog"><p>${this.botProblem}</p></div></div>`)
        let btn = document.createElement('a')
        btn.setAttribute('href', 'javascript:;')
        btn.setAttribute('class', 'record')
        btn.append(document.createTextNode('>> 点击返回人工客服 <<'))
        btn.onclick = () => {
          this.isArtificial = true
          this.botShow = false
        }

        $('.dialogBox .el-scrollbar__view').append(btn)
        this.isArtificial = true
        this.botShow = false
        this.botProblem = ''
        return
      }

      if (this.botProblem == '商务') {
        let that = this
        this.$nextTick(() => {
          $('.dialogBox .el-scrollbar__view').append(`<div class="user"><div class="userDialog"><p>${this.botProblem}</p></div></div>`)
          this.botProblem = ''
          if (this.userInfo.crm_id != 0) {
            let bot = `
          <div class="bot">
            <div class="botDialog">
              <p class="Text">可以在工作台看到“我的商务经理”，如果为空，可以联系400-8323-611进行咨询反馈</p>
            </div>
          </div>
          `
          } else {
            let bot = `
          <div class="bot">
            <div class="botDialog">
              <p class="Text">可以搜索微信号yinolink，或者扫描以下二维码添加我们的官方微信进行咨询</p><br/>
              <img src="https://yinolink.com/media/home/15834846459675.jpg" style="width:150px;height:150px;">
            </div>
          </div>
          `
          }

          //插入进去
          setTimeout(function () {
            $('.dialogBox .el-scrollbar__view').append(bot)
            that.$refs.scrollbarRef.wrap.scrollTop = that.$refs.scrollbarRef.wrap.scrollHeight
          }, 1500)
        })
        return
      }

      let that = this
      let chatbotProblem = this.botProblem
      this.botProblem = ''
      this.$nextTick(() => {
        let user = `<div class="user"><div class="userDialog"><p>${chatbotProblem}</p></div></div>`
        $('.dialogBox .el-scrollbar__view').append(user)
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
      })

      const {data: res} = await this.$http.post('cloudfb/keyword', {
        content: chatbotProblem
      })
      if (this.taskNum < 6) {
        this.$bus.emit('getTask')
      }
      let tip = `
            <div class="bot loading">
          <div class="botDialog">
            <p class="Text">
              正在输入...
            </p>
          </div>
        </div>
        `

      $('.dialogBox .el-scrollbar__view').append(tip)
      if (res.type == 1) {
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
        let content = $('.bot.loading')
            .children('.botDialog')
            .children('.Text')
            .html()
        if (content) {
          $('.bot.loading')
              .children('.botDialog')
              .children('.Text')
              .html('看看这些问题对您有帮助么')
          $('.bot.loading').removeClass('loading')
        } else {
          let tip = `
            <div class="bot">
          <div class="botDialog">
            <p class="Text">
             看看这些问题对您有帮助么
            </p>
          </div>
        </div>
        `
          $('.dialogBox .el-scrollbar__view').append(tip)
        }

        //机器人回答
        let active = '<div class="bot_link">' + '<div class="botDialog">'
        res.data.forEach((item, index) => {
          active +=
              '<a href="https://yinocloud.yinolink.com/help/' +
              item.id +
              '?type=1&login=' +
              this.userInfo.id +
              '&p=' +
              res.p +
              '" target="_blank">' +
              item.name +
              '</a>'
        })
        active +=
            `</div><div class="helpful"><i class="iconfont icon-xiaolian smile" _id="` +
            res.p +
            `"></i><i class="iconfont icon-kulian cry" _id="` +
            res.p +
            `"></i></div></div>`
        $('.dialogBox .el-scrollbar__view').append(active)
        // 点击笑脸
        $('.smile').click(async function () {
          // 变颜色
          if ($(this).css('color') == 'rgb(43, 130, 255)') {
            //取消
            $(this).css('color', '#8a8a8a')
            const {data: res} = await that.$http.post('cloudfb/document_feedback', {
              id: $(this).attr('son_id'),
              type: 4
            })
            return false
          }
          let id = $(this).attr('_id')
          $(this)
              .siblings('.cry')
              .css('color', '#8a8a8a')
          $(this).css('color', '#2b82ff')
          const {data: res} = await that.$http.post('cloudfb/document_feedback', {
            id: id,
            content: '有帮助',
            type: 1
          })
          $(this).attr('son_id', res.id)
        })
        // 点击哭脸
        $('.cry').click(function () {
          $(this)
              .siblings('.smile')
              .css('color', '#8a8a8a')
          if ($(this).css('color') == 'rgb(43, 130, 255)') {
            $(this).css('color', '#8a8a8a')
            return false
          }
          $(this).css('color', '#2b82ff')
          let bot =
              '<div class="bot loading">' + '<div class="botDialog">' + '<p class="Text">正在输入...</p>' + '</div></div>'
          // 插入进去
          $('.dialogBox .el-scrollbar__view').append(bot)
          let t = $(this)
          setTimeout(function () {
            $('.bot.loading')
                .children('.botDialog')
                .children('.Text')
                .html(
                    '很抱歉未能解决您的问题，您可以填写' +
                    `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
                    '，工作人员会在1-3个工作日内尽快回复您。'
                )
            $('.bot.loading').removeClass('loading')
            // $('#send').attr('_type', 1)
            // $('#send').attr('_id', t.attr('_id'))
            // $('#WT').attr('placeholder', '请详细叙述您的问题')
            that.$refs.scrollbarRef.wrap.scrollTop = that.$refs.scrollbarRef.wrap.scrollHeight
          }, 1000)
        })
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
      } else {
        let content = $('.bot.loading')
            .children('.botDialog')
            .children('.Text')
            .html()
        //未查询到文章
        if (content) {
          $('.bot.loading')
              .children('.botDialog')
              .children('.Text')
              .html(
                  '很抱歉未能解决您的问题，您可以填写' +
                  `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
                  '，工作人员会在1-3个工作日内尽快回复您。'
              )
          $('.bot.loading').removeClass('loading')
          // $('#send').attr('_type', 1)
          // $('#send').attr('_id', res.p)
          // $('#WT').attr('placeholder', '请详细叙述您的问题')
        } else {
          //对话框加上这句话 或者选择提交你的问题，等待专家的解答：<button>提交问题</button>
          let bot =
              ' <div class="bot">' +
              '<div class="botDialog">' +
              '<p class="Text">很抱歉未能解决您的问题，您可以填写' +
              `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
              '，工作人员会在1-3个工作日内尽快回复您。</p>' +
              '</div></div>'
          $('.dialogBox .el-scrollbar__view').append(bot)
          // $('#send').attr('_type', 1)
          // $('#send').attr('_id', res.p)
          // $('#WT').attr('placeholder', '请详细叙述您的问题')
        }
      }

      this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
    },
    chatBotDialogMini() {
      this.tipShow1 = false
      this.tipShow2 = false
      this.tipShow3 = false
      this.botShow = false
      clearInterval(this.freeClock)
      clearTimeout(this.thirdClock)
      clearTimeout(this.fiveClock)
    },
    chatBotDialogClose() {
      this.tipShow1 = false
      this.tipShow2 = false
      this.tipShow3 = false
      this.botShow = false
    },
    // 点击获取历史记录
    async getRecord(page) {
      const {data: res} = await this.$http.post('cloudfb/chat_record', {
        page: page
      })
      let list = []
      if (res.code != 200) {
        return false
      }
      $('.record').remove()
      //循环获取历史记录
      if (res.total_page > page) {
        list += '<a href="javascript:;" class="record appendRecord">点击查看历史咨询记录</a>'
      }
      res.data.forEach(item => {
        if (item.type == 2) {
          list += '<div class="bot_link">' + '<div class="botDialog">'
          item.problem.forEach(pro => {
            list += '<a href="https://yinocloud.yinolink.com/help/' + pro.id + '?type=1">' + pro.name + '</a>'
          })
          list += '</div></div>'
        } else {
          list +=
              '<div class="user">' + '<div class="userDialog">' + '<p>' + item.problem + '</p>' + '</div>' + '</div>'
        }
      })
      $('.dialogBox .el-scrollbar__view').prepend(list)
      // 动态添加点击历史按钮事件
      $('.appendRecord').click(function () {
        window.getRecord(res.page)
      })
    },
    async getProList(p) {
      let that = this
      this.$nextTick(() => {
        let user = `<div class="user"><div class="userDialog"><p>${p.name}</p></div></div>`
        if (p.id > 0) {
          $('.dialogBox .el-scrollbar__view').append(user)
        }
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
      })
      const {data: res} = await this.$http.post('cloudfb/search', {
        id: p.id,
        name: p.name
      })
      if (this.taskNum < 6) {
        this.$bus.emit('getTask')
      }
      if (p.id > 0) {
        let tip = `
          <div class="bot loading">
            <div class="botDialog">
              <p class="Text">正在输入...</p>
            </div>
          </div>
        `

        $('.dialogBox .el-scrollbar__view').append(tip)
      }
      if (res.type == 1) {
        let content = $('.bot.loading')
            .children('.botDialog')
            .children('.Text')
            .html()
        if (content) {
          $('.bot.loading')
              .children('.botDialog')
              .children('.Text')
              .html('点击底部的选项，让我更好的回答您的问题吧~')
          $('.bot.loading').removeClass('loading')
        } else {
          let tip = `<div class="bot">
              <div class="botDialog">
                <p class="Text">
                点击底部的选项，让我更好的回答您的问题吧~
                </p>
              </div>
            </div>`
          $('.dialogBox .el-scrollbar__view').append(tip)
        }

        this.problem = res.data
        let back = new Array()
        back['id'] = 0
        back['name'] = '返回'
        this.problem.unshift(back)
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
        this.$nextTick(() => {
          $('.swiper-slide').each(function () {
            let span = $(this)
                .children('span')
                .html()
            if (span == '返回') {
              $(this).css({
                color: '#262626',
                background: 'none',
                border: 'none'
              })
            }
          })
        })
      } else if (res.type == 4) {
        this.problem = res.data
      } else if (res.type == 2) {
        let content = $('.bot.loading')
            .children('.botDialog')
            .children('.Text')
            .html()

        if (content) {
          $('.bot.loading')
              .children('.botDialog')
              .children('.Text')
              .html('看看这些问题对您有帮助么')
          $('.bot.loading').removeClass('loading')
        } else {
          let tip = `
            <div class="bot">
          <div class="botDialog">
            <p class="Text">
            看看这些问题对您有帮助么
            </p>
          </div>
        </div>
        `
          $('.dialogBox .el-scrollbar__view').append(tip)
        }
        // this.answer = res.data
        // 机器人回答
        let active = '<div class="bot_link">' + '<div class="botDialog">'
        res.data.forEach((item, index) => {
          active +=
              '<a href="https://yinocloud.yinolink.com/help/' +
              item.id +
              '?type=1&login=' +
              this.userInfo.id +
              '&p=' +
              res.p +
              '" target="_blank">' +
              item.name +
              '</a>'
        })
        active +=
            `</div><div class="helpful"><i class="iconfont icon-xiaolian smile" _id="` +
            res.p +
            `"></i><i class="iconfont icon-kulian cry" _id="` +
            res.p +
            `"></i></div></div>`
        $('.dialogBox .el-scrollbar__view').append(active)
        // 点击笑脸
        $('.smile').click(async function () {
          // 变颜色
          if ($(this).css('color') == 'rgb(43, 130, 255)') {
            //取消
            $(this).css('color', '#8a8a8a')
            const {data: res} = await that.$http.post('cloudfb/document_feedback', {
              id: $(this).attr('son_id'),
              type: 4
            })
            return false
          }
          let id = $(this).attr('_id')
          $(this)
              .siblings('.cry')
              .css('color', '#8a8a8a')
          $(this).css('color', '#2b82ff')
          const {data: res} = await that.$http.post('cloudfb/document_feedback', {
            id: id,
            content: '有帮助',
            type: 1
          })
          $(this).attr('son_id', res.id)
        })
        // 点击哭脸
        $('.cry').click(function () {
          $(this)
              .siblings('.smile')
              .css('color', '#8a8a8a')
          if ($(this).css('color') == 'rgb(43, 130, 255)') {
            $(this).css('color', '#8a8a8a')
            $('#send').attr('_type', 0)
            return false
          }
          $(this).css('color', '#2b82ff')
          let bot =
              '<div class="bot loading">' + '<div class="botDialog">' + '<p class="Text">正在输入...</p>' + '</div></div>'
          // 插入进去
          $('.dialogBox .el-scrollbar__view').append(bot)

          let t = $(this)
          setTimeout(function () {
            $('.bot.loading')
                .children('.botDialog')
                .children('.Text')
                .html(
                    '很抱歉未能解决您的问题，您可以填写' +
                    `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
                    '，工作人员会在1-3个工作日内尽快回复您。'
                )
            // $('.bot.loading').removeClass('loading')
            // $('#send').attr('_type', 1)
            // $('#send').attr('_id', t.attr('_id'))
            // $('#WT').attr('placeholder', '请详细叙述您的问题')
            that.$refs.scrollbarRef.wrap.scrollTop = that.$refs.scrollbarRef.wrap.scrollHeight
          }, 1000)
        })
        this.$refs.scrollbarRef.wrap.scrollTop = this.$refs.scrollbarRef.wrap.scrollHeight
      } else {
        let content = $('.bot.loading')
            .children('.botDialog')
            .children('.Text')
            .html()
        //未查询到文章
        if (content) {
          $('.bot.loading')
              .children('.botDialog')
              .children('.Text')
              .html(
                  '很抱歉未能解决您的问题，您可以填写' +
                  `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
                  '，工作人员会在1-3个工作日内尽快回复您。'
              )
          // $('.bot.loading').removeClass('loading')
          // $('#send').attr('_type', 1)
          // $('#send').attr('_id', res.p)
          // $('#WT').attr('placeholder', '请详细叙述您的问题')
        } else {
          //对话框加上这句话
          let bot =
              ' <div class="bot">' +
              '<div class="botDialog">' +
              '<p class="Text">很抱歉未能解决您的问题，您可以填写' +
              `<a style="display: inline-block;margin-bottom:0;overflow: visible;" href="${base.url}/feedback">问题反馈</a>` +
              '，工作人员会在1-3个工作日内尽快回复您。</p>' +
              '</div></div>'
          $('.dialogBox .el-scrollbar__view').append(bot)
          $('#send').attr('_type', 1)
          $('#send').attr('_id', res.p)
          $('#WT').attr('placeholder', '请详细叙述您的问题')
        }
      }
    },
    getChatBotSteps() {
      if (this.tipShow1 || this.tipShow2 || this.tipShow3) {
        return
      } else {
        this.tipShow1 = true
      }
    },
    skip() {
      this.tipShow1 = false
      this.tipShow2 = false
      this.tipShow3 = false
      this.guideWrapShow = 1
    },
    firstStep() {
      this.tipShow1 = false
      this.tipShow2 = true
      this.guideWrapShow = 0
    },
    secondStep() {
      this.tipShow2 = false
      this.tipShow3 = true
    },
    doneStep() {
      this.tipShow3 = false
      this.guideWrapShow = 1
    },
  }
}
</script>

<style lang="less" scoped>
.chatBotDialog {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 390px;
  height: auto;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  z-index: 2060;
  z-index: 9999;

  header {
    width: 100%;
    height: 100px;
    background-image: linear-gradient(#4785f2, #5ec3c5);
    text-align: center;
    overflow: hidden;

    h2 {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      margin: 15px 0 0;
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      margin: 15px 0 0;
    }

    .closeBtn,
    .miniBtn {
      position: absolute;
      top: 12px;
      right: 15px;
      font-size: 30px;
      color: #fff;
      cursor: pointer;
    }

    .miniBtn {
      right: 50px;
    }
  }

  section {
    border-radius: 20px 20px 0 0;
    background: #fafafa;
    margin-top: -15px;
    padding: 16px;
    z-index: 2060;

    .record {
      display: block;
      font-size: 14px;
      color: #2b82ff;
      text-align: center;
      text-decoration: none;
      margin: 16px 0;
      margin-top: 0;
    }

    .botDialog {
      display: inline-block;
      width: auto;
      max-width: 80%;
      height: auto;
      background: #fff;
      border-radius: 8px;
      margin: 6px;
      padding: 6px;

      p {
        margin: 0;
      }

      a {
        display: block;
        font-size: 14px;
        color: #2b82ff !important;
        margin-bottom: 6px;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:last-child {
        margin-bottom: 0;
      }
    }

    .user {
      text-align: right;

      .userDialog {
        display: inline-block;
        width: auto;
        max-width: 80%;
        height: auto;
        background: #2b82ff;
        border-radius: 8px;
        margin: 6px;
        padding: 6px;
        word-break: break-all;

        p {
          font-size: 14px;
          color: #fff;
          text-align: left;
          margin: 0;

          :deep(a) {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }

  .bot_link {
    display: flex;

    .helpful {
      display: flex;
      align-items: flex-end;

      i {
        font-size: 22px;
        color: #8a8a8a;
        margin-right: 10px;
      }
    }
  }

  footer {
    width: 100%;
    height: auto;
    background: #fafafa;
    padding: 16px 0;

    .swiper-slide {
      min-height: 19px;
    }

    .proList {
      padding: 0 16px;
      margin: 8px 0;
    }

    .search {
      padding: 0 16px;
    }
  }
}

.UserGuideWrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.GuideRange1,
.GuideRange2,
.GuideRange3 {
  position: absolute;
  top: 8px;
  right: 0;
  width: 100%;
  z-index: 1;

  img {
    width: 388px;
  }
}

.GuideRange2,
.GuideRange3 {
  top: 460px;
  height: 90px;
}

// 排队提示
.queueTip,
.closeTip {
  a {
    color: #2b82ff;
    text-decoration: none;
  }

  width: 80%;
  padding: 6px;
  margin: 6px auto;
  background-color: #e9e9e9;
  border-radius: 8px;

  p {
    margin: 0;
    text-align: left;

    .cancelQuene {
      cursor: pointer;
    }
  }
}

.el-rate {
  display: inline-block;
}

.el-image__placeholder {
  background: url('https://img0.baidu.com/it/u=666320934,1539077995&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500') no-repeat 50% 50% !important;
  background-size: 50% !important;
}

:deep(#urlA) {
  overflow: inherit;
  white-space: normal;
  word-break: break-all;
}

.dialogueMask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;

  .manualDialog {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 95%;
    background: #fff;
    border-radius: 20px;

    .icon-ylclose {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      color: #595959;
      cursor: pointer;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .icon-yltuichu {
        font-size: 28px;
        color: #595959;
        margin-bottom: 30px;
      }

      .Text {
        margin-bottom: 20px;
      }
    }
  }
}

.chatbot {
  position: fixed;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  z-index: 99;
  width: 100px;
  height: 100px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;

  .chatbotBtn {
    font-size: 50px;
    color: #595959;
  }

  .chatbotImg,
  .chatbotOpenImg {
    width: auto;
    height: 100px;
  }
}
</style>