<template>
  <el-container class="home-container">
    <!-- <div class="pagePop" ref="pagePop" v-if="guideShowable()" @click="pagePopTip"></div> -->
    <div style="position: relative; box-shadow: 0 1px 8px rgb(0, 0, 0, 0.2); background-color: #fff">
      <el-aside :width="isCollapse ? '64px' : '230px'">
        <div :class="'asideLogn'">
          <img src="../assets/MMCloud_collapse.svg" alt="" @click="logoLink" class="miniImg" v-if="isCollapse" />
          <img src="../assets/MMCloud.svg" alt="" @click="logoLink" class="largeImg" v-else />
        </div>
        <!-- 侧边栏菜单区域 -->
        <el-scrollbar>
          <el-menu background-color="#fff" text-color="#262626" active-text-color="#2b82ff" unique-opened
            :collapse-transition="false" :collapse="isCollapse" router :default-active="$route.path">
            <el-menu-item :index="'/' + 'homepage'" v-if="$store.getters.userInfo.crm_id != 0 || isQbitSource">
              <i class="iconfont icon-ylgongzuotai" style="font-size: 24px"></i>
              <span>{{ isQbitSource ? '渠道推荐客户' : '工作台' }}</span>
            </el-menu-item>

            <!-- 一级菜单 -->
            <el-sub-menu :index="item.id + ''" v-for="item in menulist" :key="item.id">
              <!-- 一级菜单的模板区域 -->
              <template #title>
                <!-- 图标 -->
                <i :class="iconsObj[item.id]"></i>
                <!-- 文本 -->
                <span>{{ item.name }}</span>
              </template>

              <!-- 二级菜单 -->
              <el-menu-item :index="'/' + subItem.path"
                v-for="subItem in item.data.filter(item => item.path !== 'recharge' && item.path !== 'member' && item.path !== 'message')"
                :key="subItem.id" @click="saveNavState('/' + subItem.path)">
                <template #title>
                  <!-- 文本 -->
                  <span>{{ subItem.name }}</span>
                </template>
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar>

        <!-- 快捷入口E -->
        <!-- <div class="menuPop" v-if="guideShowable()" @click="menuPopTip"></div> -->
      </el-aside>
    </div>
    <el-container>
      <el-header>
        <i class="iconfont" :class="isCollapse ? 'icon-ylzhankai' : 'icon-ylshouqi'" id="toggle-btn"
          @click="toggleCollapse"></i>
        <div>
          <!-- 新手任务 -->
          <!-- <el-dropdown class="taskDrop" ref="dropdownRef" v-if="guideShowable()"> -->
          <!-- <span class="el-dropdown-link"> -->
          <!-- <div style="z-index: 6"> -->
          <!-- <div class="WJB" :style="{ opacity }" v-if="guideShowable()"> -->
          <!-- <span> 新手任务 </span> -->
          <!-- <transition appear name="animate__animated animate__bounce" enter-active-class="animate__flip" -->
          <!-- leave-active-class="animate__rubberBand"> -->
          <!-- <img src="../assets/gift.png" alt="" style="width: 20px; vertical-align: text-top" -->
          <!-- v-show="giftShow" /> -->
          <!-- </transition> -->
          <!-- </div> -->
          <!-- </div> -->
          <!-- </span> -->
          <!-- <template #dropdown> -->
          <!-- <el-dropdown-menu> -->
          <!-- <el-dropdown-item> -->
          <!-- <h3 class="Text" style="text-align: center; margin: 0"> -->
          <!-- 新手任务进度<span style="color: #2b82ff">({{ taskNum }}/6)</span> -->
          <!-- </h3> -->
          <!-- </el-dropdown-item> -->
          <!-- <el-dropdown-item> -->
          <!-- <ul class="newTask"> -->
          <!-- <li v-for="(t, index) in newTaskList" :key="index"> -->
          <!-- <i class="el-icon-refresh-right warningColor" v-if="t.type == 1"></i> -->
          <!-- <i class="el-icon-finished successColor" v-else></i> -->
          <!-- <span class="taskName">{{ t.name }}</span> -->
          <!-- <span class="taskCode">+10</span> -->
          <!-- <el-button type="primary" size="mini" v-if="t.type == 1" @click="goTaskUrl(t.url, t.param)">去完成 -->
          <!-- </el-button> -->
          <!-- <el-button disabled size="mini" v-else>已完成</el-button> -->
          <!-- </li> -->
          <!-- </ul> -->
          <!-- </el-dropdown-item> -->
          <!-- </el-dropdown-menu> -->
          <!-- </template> -->
          <!-- </el-dropdown> -->

          <!-- 反馈入口 -->
          <router-link to="/feedback">
            <el-popover placement="bottom" width="auto" trigger="hover" content="问题反馈">
              <template #reference>
                <i class="iconfont icon-ylfankui"></i>
              </template>
            </el-popover>
          </router-link>

          <!-- 消息通知图标 -->
          <!-- <el-badge :value="msgTotal" class="item" v-if="msgTotal != 0"> -->
          <!-- <router-link to="/message"><i class="iconfont icon-ylxiaoxi"></i></router-link> -->
          <!-- </el-badge> -->
          <!-- <el-badge v-else> -->
          <!-- <router-link to="/message"><i class="iconfont icon-ylxiaoxi"></i></router-link> -->
          <!-- </el-badge> -->
          <div style="margin-left: 20px"></div>

          <!-- <router-link to="/hc" target="_blank"> -->
          <!-- <el-popover placement="bottom" width="auto" trigger="hover" content="点击前往帮助中心"> -->
          <!-- <template #reference> -->
          <!-- <i class="iconfont icon-ylbangzhu Text" style="cursor: pointer"></i> -->
          <!-- </template> -->
          <!-- </el-popover> -->
          <!-- </router-link> -->

          <el-dropdown trigger="hover" max-height="auto" style="z-index: 9">
            <span class="el-dropdown-link">
              <el-avatar :src="$store.getters.userInfo.w_headimgurl"></el-avatar>
              <span class="Text">{{ $store.getters.userInfo.name }}</span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <template v-if="!isQbitSource">
                  <div>
                    <el-row :gutter="20" align="middle" style="padding: 10px 15px">
                      <el-col :span="6" style="text-align: center">
                        <el-avatar :src="$store.getters.userInfo.w_headimgurl" fit="fill"></el-avatar>
                      </el-col>
                      <el-col :span="18">
                        <span class="SmallHead" style="display: inline-block; max-width: 200px" v-if="$store.getters.userInfo.company_status == 1 || $store.getters.userInfo.company_status == 2
                          ">
                          {{ $store.getters.userInfo.company }}
                        </span>
                        <span class="Text" style="display: inline-block; max-width: 200px" v-else>企业还未认证</span>
                        <!-- <div style="margin-top: 10px"> -->
                        <!-- <router-link to="/member"> -->
                        <!-- <el-tag size="small" v-show="points < 2000" -->
                        <!-- ><i class="iconfont icon-anchor-full"></i> V1 水手 -->
                        <!-- </el-tag> -->
                        <!-- </router-link> -->
                        <!-- <router-link to="/member"> -->
                        <!-- <el-tag size="small" v-show="points >= 2000 && points < 5000" -->
                        <!-- ><i class="iconfont icon-anchor-full"></i> V2 三副 -->
                        <!-- </el-tag> -->
                        <!-- </router-link> -->
                        <!-- <router-link to="/member"> -->
                        <!-- <el-tag size="small" v-show="points >= 5000 && points < 20000" -->
                        <!-- ><i class="iconfont icon-anchor-full"></i> V3 二副 -->
                        <!-- </el-tag> -->
                        <!-- </router-link> -->
                        <!-- <router-link to="/member"> -->
                        <!-- <el-tag size="small" v-show="points >= 20000 && points < 50000" -->
                        <!-- ><i class="iconfont icon-anchor-full"></i> V4 大副 -->
                        <!-- </el-tag> -->
                        <!-- </router-link> -->
                        <!-- <router-link to="/member"> -->
                        <!-- <el-tag size="small" v-show="points >= 50000" -->
                        <!-- ><i class="iconfont icon-anchor-full"></i> V5 船长 -->
                        <!-- </el-tag> -->
                        <!-- </router-link> -->
                        <!-- </div> -->
                      </el-col>
                    </el-row>
                    <el-row :gutter="10" align="middle" justify="space-between" class="erweimaRow">
                      <!-- <el-col :span="12" style="text-align: center" v-if="$store.getters.userInfo.salesman_name"> -->
                      <!-- <div class="hoverColor"> -->
                      <!-- <el-popover placement="left-start" :width="150" trigger="hover"> -->
                      <!-- <img :src="$store.getters.userInfo.salesman_img" alt="" :width="100" :height="100" -->
                      <!-- style="display: block; margin: 0 auto" /> -->
                      <!-- <p class="Text" style="text-align: center; margin: 5px 0"> -->
                      <!-- {{ $store.getters.userInfo.salesman_name }} -->
                      <!-- </p> -->
                      <!-- <p class="Text" style="text-align: center; margin: 0"> -->
                      <!-- {{ $store.getters.userInfo.salesman_phone }} -->
                      <!-- </p> -->
                      <!-- <p class="HelpText" style="text-align: center; margin: 5px 0">扫一扫添加微信沟通</p> -->
                      <!-- <template #reference> -->
                      <!-- <i class="iconfont icon-ylerweima"></i> -->
                      <!-- </template> -->
                      <!-- </el-popover> -->
                      <!-- <span class="Text" style="display: block">商务经理</span> -->
                      <!-- </div> -->
                      <!-- </el-col> -->
                      <el-col :span="12" style="text-align: center">
                        <router-link to="/clientInfo">
                          <div class="hoverColor">
                            <i class="iconfont icon-ylqiyexinxi"></i>
                            <span class="Text" style="display: block">企业信息</span>
                          </div>
                        </router-link>
                      </el-col>
                    </el-row>
                  </div>
                  <router-link to="/users">
                    <el-dropdown-item :divided="true">
                      <i class="iconfont icon-ylgerenzhongxin"></i>
                      <span>用户信息</span>
                    </el-dropdown-item>
                  </router-link>
                </template>
                <!-- <el-link :underline="false" href="https://yinolink.com?invite=ychelp" target="_blank"> -->
                <!-- <el-dropdown-item> -->
                <!-- <i class="iconfont icon-ylguanwang"></i> -->
                <!-- <span>YinoLink易诺官网</span> -->
                <!-- </el-dropdown-item> -->
                <!-- </el-link> -->
                <!-- <el-link :underline="false" -->
                <!-- :href="`https://academy.yinolink.com?source=head&am=${$store.getters.userInfo.token}`" target="_blank"> -->
                <!-- <el-dropdown-item> -->
                <!-- <i class="iconfont icon-ylxueyuan"></i> -->
                <!-- <span>YinoLink周5出海营销学院</span> -->
                <!-- </el-dropdown-item> -->
                <!-- </el-link> -->
                <el-dropdown-item @click="logout">
                  <i class="iconfont icon-yltuichu"></i>
                  <span>退出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>

        <el-footer>
          <ul class="footerLink" style="margin-bottom: 20px">
            <!-- <router-link to="/hc" class="Text" tag="a" target="_blank"><span class="Text">帮助</span></router-link> -->
            <el-link href="https://mmcloud.yinolink.com/privacy.html" class="Text" :underline="false"
              target="_blank"><span class="Text">隐私</span></el-link>
            <el-link :underline="false" class="Text" href="https://mmcloud.yinolink.com/service.html"
              target="_blank"><span class="Text">条款</span></el-link>
          </ul>
          <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank"
            style="width: auto; text-align: center">
            <span class="Text">
              杭州千凤文化传媒有限公司版权所有Copyright © 2020
              <span><img src="@/assets/officeIcon.png" alt="" style="vertical-align: text-bottom" /></span>
              <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017562" rel="noreferrer"
                target="_blank">浙公网安备33011002017562</a>
            </span>
          </el-link>
        </el-footer>
      </el-main>
      <!-- <ChatBot v-if="$store.getters.userInfo.exc !== 'qbit'" ref="chatBotRef" /> -->
    </el-container>

    <!-- 已进行企业认证用户引导 -->
    <div class="UserGuideWrap" v-show="this.guideWrapOldShow == 0">
      <el-tooltip placement="right" effect="light" :manual="true" v-model="tipShow3">
        <template #content>
          <p class="guideTitle">工作台</p>
          <p class="guideDescription" style="max-width: 250px">数据总览已经升级为工作台，样式升级体验更佳。</p>
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="thirdStep">下一步</el-button>
          </div>
        </template>
        <div class="GuideRange3" v-show="tipShow3">工作台</div>
      </el-tooltip>

      <el-tooltip placement="right" effect="light" :manual="true" v-model="tipShow4">
        <template #content>
          <p class="guideTitle">账户管理</p>
          <p class="guideDescription" style="max-width: 250px">Facebook和TikTok广告账户相关信息已合并在账户管理中。</p>
          <div style="text-align: right; margin: 0">
            <el-button type="text" size="mini" style="color: #2b82ff" @click="lastThirdStep">上一步</el-button>
            <el-button type="primary" size="mini" @click="guideDone">完成</el-button>
          </div>
        </template>
        <div class="GuideRange4" v-show="tipShow4">账户管理</div>
      </el-tooltip>
    </div>

    <!-- 新注册用户引导 -->
    <div class="UserGuideWrap" v-show="guideWrapNewShow == 0">
      <el-tooltip placement="right" effect="light" :manual="true" v-model="tipShow1">
        <template #content>
          <p class="guideTitle">用户信息</p>
          <p class="guideDescription" style="max-width: 250px">这里可完善自己的信息和设置密码</p>
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="firstStep">下一步</el-button>
          </div>
        </template>
        <div :class="'GuideRange1 ' + ($store.getters.userInfo.crm_id > 0 ? '' : 'unVerify')" v-show="tipShow1">
          用户信息
        </div>
      </el-tooltip>

      <el-tooltip placement="right" effect="light" :manual="true" v-model="tipShow2">
        <template #content>
          <p class="guideTitle">企业认证</p>
          <p class="guideDescription" style="max-width: 250px">提交企业信息认证后，就可以进行账户管理了</p>
          <div style="text-align: right; margin: 0">
            <el-button type="text" size="mini" style="color: #2b82ff" @click="lastFirstStep">上一步</el-button>
            <el-button type="primary" size="mini" @click="secondStep">完成</el-button>
          </div>
        </template>
        <div :class="'GuideRange2 ' + ($store.getters.userInfo.crm_id > 0 ? '' : 'unVerify')" v-show="tipShow2">
          企业认证
        </div>
      </el-tooltip>
    </div>
  </el-container>

  <!-- 满意度问卷 -->
  <!-- <el-dialog v-model="QuestionDialogVisible" title="YinoCloud网站满意度调查" top="5vh" custom-class="question">
    <iframe width="100%" height="700" src="https://wj.qq.com/s2/9466041/7d05/" frameborder="0" allowfullscreen></iframe>
  </el-dialog> -->

  <!-- 会员等级升级提示弹窗 -->
  <!-- <div class="upgrade_warp" v-show="isUpgrade == 1"> -->
  <!-- <div class="upgrade_bg" @click="goMember"> -->
  <!-- <div class="upgrade_close" @click="upgrade_close"> -->
  <!-- <img src="../assets/upgrade_close.png" alt="" /> -->
  <!-- </div> -->
  <!-- <div class="grade_title"> -->
  <!-- <span class="grade_name" v-show="upgrade_type == 2">V2 三副</span> -->
  <!-- <span class="grade_name" v-show="upgrade_type == 3">V3 二副</span> -->
  <!-- <span class="grade_name" v-show="upgrade_type == 4">V4 大副</span> -->
  <!-- <span class="grade_name" v-show="upgrade_type == 5">V5 船长</span> -->
  <!-- </div> -->
  <!-- <div class="grade_icon" style="width: 33%"> -->
  <!-- <div class="icon_content" v-show="upgrade_type == 2"> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylzhuanshuguanjia"></i> -->
  <!-- <div class="title">专属管家</div> -->
  <!-- </div> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylyaoqingtequan"></i> -->
  <!-- <div class="title">邀请特权</div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!--  -->
  <!-- <div class="grade_icon" style="width: 58%"> -->
  <!-- <div class="icon_content" v-show="upgrade_type == 3"> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-yllvsetongdao"></i> -->
  <!-- <div class="title">绿色通道</div> -->
  <!-- </div> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylbimenkecheng"></i> -->
  <!-- <div class="title">闭门课程</div> -->
  <!-- </div> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylguanggaoshuju"></i> -->
  <!-- <div class="title">数据复盘</div> -->
  <!-- </div> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylzhanghuchongzhi"></i> -->
  <!-- <div class="title">账户充值</div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!--  -->
  <!-- <div class="grade_icon" style="width: 48%"> -->
  <!-- <div class="icon_content" v-show="upgrade_type == 4"> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylsucaisheji"></i> -->
  <!-- <div class="title">免费素材设计</div> -->
  <!-- </div> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylzhikejingli"></i> -->
  <!-- <div class="title">优先推荐直客经理</div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!--  -->
  <!-- <div class="grade_icon" style="width: 58%; top: 71%"> -->
  <!-- <div class="icon_content" style="justify-content: center" v-show="upgrade_type == 5"> -->
  <!-- <div class="icon"> -->
  <!-- <i class="iconfont icon-ylyingxiaocelve"></i> -->
  <!-- <div class="title">全渠道营销策略</div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- <div class="upgrade_gif"></div> -->
  <!-- </div> -->
</template>

<script>
import 'animate.css'
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import { ArrowDown } from '@element-plus/icons'
import { ElMessage, ElMessageBox } from 'element-plus'
import { nextTick } from 'vue'
import ChatBot from '@/views/base/ChatBot'
import base from '@/request/base'

export default {
  components: { ArrowDown, ChatBot },
  data() {
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入有效的电话号码'))
    }
    return {
      chatbotOpen: 0,
      isQuestion: 0,
      // 用户信息
      userInfo: '',
      //左侧菜单数据
      menulist: [],
      iconsObj: {
        14: 'iconfont icon-ylgerenzhongxin',
        34: 'iconfont icon-ylkaihuguanli',
        16: 'iconfont icon-ylzhanghuguanli',
        29: 'iconfont icon-ylguanggaoguanli',
        38: 'iconfont icon-ylsucaiguanli',
        22: 'iconfont icon-ylcaiwuguanli',
      },
      // 是否折叠
      isCollapse: window.frames.length !== parent.frames.length,
      // 被激活的链接地址
      activePath: '',
      guideWrapNewShow: 1,
      guideWrapOldShow: 1,
      guideWrapOldShow1: 1,
      // 新用户引导
      tipShow1: false,
      tipShow2: false,

      // 已认证客户引导
      tipShow3: false,
      tipShow4: false,
      tipShow5: false,
      // 消息通知内容
      msgTotal: 0,
      noticeTitle: '',
      noticeContent: '',
      noticeType: '',
      msgContent: '',
      msgType: '',

      QuestionDialogVisible: false,
      isWJ: '',
      // 问卷按钮透明度
      opacity: 1,
      giftShow: false,
      timeFlag: false,
      // 箭头动画效果
      arrShow: false,
      points: 0,
      newTaskList: [],
      taskNum: 0,
      driver: '',
      FinishType: '',
      // 中间全局提示
      globalNotice: '',
      isUpgrade: 0,
      upgrade_type: 0,
      socket: null,
    }
  },
  mounted() {
    // 获取localStorage中的用户信息
    this.getMenuList()
    // this.getNewTask()
    this.isAuthentication()
    this.getBackEndMsg()
    this.getMsgTotal()
    this.wjTip()
    this.changeOpacity()
    this.changeGiftShow()
    this.dragChatBot()
    // this.getPopIsShow()
    this.getMessage()
    // 中间全局提示
    this.getGlobalPrompt()
    // this.upgrade()
    this.$bus.on('closeDialog', this.closeDialog)
    this.$bus.on('getMsgTotal', this.getMsgTotal)
    // this.$bus.on('getTask', this.getNewTask)
    this.$bus.on('closeQuestionDialog', this.closeQuestionDialog)
  },
  beforeUnmount() {
    ElMessage.closeAll()
  },
  computed: {
    isQbitSource: (el) => {
      return el.$store.getters.userInfo && el.$store.getters.userInfo.q_type
    },
  },
  methods: {
    // 拖动机器人方法
    dragChatBot() {
      this.$nextTick(() => {
        // 拖动机器人
        function drag() {
          //1、获取拖拽的元素
          var chatBot = document.querySelector('#ChatBot')
          //2、拖拽，先点击。点击时获取(鼠标距离当前窗口X轴坐标-chatBot元素对应最近元素的距离，这里是body)
          //等于获取了鼠标在拖拽元素的坐标
          if (chatBot)
            chatBot.addEventListener('mousedown', function (event) {
              var e = event || window.event
              event.preventDefault()
              var disX = e.clientX - chatBot.offsetLeft
              var disY = e.clientY - chatBot.offsetTop
              document.body.onmousemove = function (event) {
                var e = event || window.event
                //3、移动时，鼠标距离当前窗口x轴坐标 - 鼠标在拖拽元素的坐标 = 剩下距离body的x轴坐标
                //将这个数值设置为拖拽元素的left、top
                var boxLeft = e.clientX - disX
                var boxTop = e.clientY - disY
                //获取body的页面可视宽高
                var clientHeight = document.documentElement.clientHeight || document.body.clientHeight
                var clientWidth = document.documentElement.clientWidth || document.body.clientWidth
                //4、限制拖拽宽高
                if (boxLeft < 0) {
                  boxLeft = 0
                  //如果拖拽元素定位的数值高于, 页面可视宽 - 拖拽元素自身宽 (可视页面最大宽)
                } else if (boxLeft > clientWidth - chatBot.offsetWidth) {
                  //满足这个条件，就限制宽为，clientWidth-oDiv.offsetWidth(可视页面最大宽)
                  boxLeft = clientWidth - chatBot.offsetWidth
                }

                if (boxTop < 0) {
                  boxTop = 0
                  //如果拖拽元素定位的数值高于, 页面可视高 - 拖拽元素自身高 (可视页面最大高)
                } else if (boxTop > clientHeight - chatBot.offsetHeight) {
                  //满足这个条件，就限制高为，clientHeight-oDiv.offsetHeight(可视页面最大高)
                  boxTop = clientHeight - chatBot.offsetHeight
                }

                //将数值设置成拖拽元素的定位left、top
                chatBot.style.left = boxLeft + 'px'
                chatBot.style.top = boxTop + 'px'
              }
            })

          document.addEventListener('mouseup', function () {
            document.body.onmousemove = null
          })
        }

        //调用这个函数
        drag()
      })
    },
    goWJ() {
      if (this.$route.path == '/wj') {
        return this.$message.warning('您已在问卷页面！')
      }

      const routeUrl = this.$router.resolve({
        path: '/wj',
      })
      window.open(routeUrl.href, '_blank')
    },
    getPopIsShow() {
      this.$nextTick(() => {
        if (this.$route.path == '/users' || this.$route.path == '/violation_local_list') {
          if (this.$refs.pagePop) this.$refs.pagePop.style.display = 'none'
        }
      })
    },
    pagePopTip() {
      this.$message.warning('请您先完成页面右上角的新手任务噢！')
    },
    menuPopTip() {
      this.$message.warning('请您先完成页面右上角的新手任务噢！')
    },
    // 问卷入口动画定时器
    changeOpacity() {
      setInterval(() => {
        this.giftShow = true
      }, 1000)
    },
    changeGiftShow() {
      setInterval(() => {
        this.giftShow = false
      }, 3000)
    },
    async wjTip() {
      this.QuestionDialogVisible = false
      const isWJ = window.localStorage.getItem('isWJ')
      if (!isWJ) {
        window.localStorage.setItem('isWJ', 1)
        this.QuestionDialogVisible = true
      }
    },
    logoLink() {
      if (!this.$store.getters.userInfo.crm_id && this.$store.getters.userInfo.exc !== 'shoplazza') {
        this.$router.push('/clientInfo')
      } else {
        this.$router.push('/home')
      }

      if (this.taskNum < 6 && this.$store.getters.userInfo.crm_id != 0) {
        this.$refs.pagePop.style.display = 'block'
      }
    },
    logout() {
      window.localStorage.clear()
      // ElMessage.closeAll()
      this.$router.push('/login')
      // socket断开连接
      // this.socket.disconnect()
    },
    getBackEndMsg() {
      console.log('暂时屏蔽socket')
      // var getMsg = this.getMsg
      // // 连接服务端，workerman.net:2120换成实际部署web-msg-sender服务的域名或者ip
      // var socket = io(base.socket_url)
      // this.socket = socket
      // // uid可以是自己网站的用户id，以便针对uid推送以及统计在线人数
      // var uid = this.$store.getters.userInfo.id
      // // socket连接后以uid登录
      // socket.on('connect', function () {
      //   socket.emit('login', uid)
      // })
      // // 后端推送来消息时
      // socket.on('new_msg', (msg) => {
      //   var msg = msg.split(';')
      //   getMsg(msg)
      // })
    },
    async getMsg(data) {
      this.noticeTitle = data[0]
      this.noticeContent = data[1]
      this.noticeType = data[2]
      if (this.noticeContent != '') {
        this.$notify({
          title: this.noticeTitle,
          dangerouslyUseHTMLString: true,
          offset: 100,
          duration: 0,
          type: this.noticeType,
          message: this.noticeContent,
        })
        const { data: res } = await this.$http.post('user/set_message', {
          type: 3,
          id: data[3],
        })
      }
    },
    // 黑名单通知
    async getMessage() {
      const { data: res } = await this.$http.post('cloudfb/ad_blacklist')
      if (res.code == 100) {
        ElMessage({
          dangerouslyUseHTMLString: true,
          duration: 0,
          showClose: true,
          message: res.msg,
          type: 'error',
        })
      }
    },
    async getMsgTotal() {
      const { data: res } = await this.$http.post('user/message')
      this.msgTotal = res.num
    },
    guideShowable() {
      return (
        this.taskNum < 6 &&
        this.$store.getters.userInfo.crm_id > 0 &&
        this.$store.getters.userInfo.crm_type == 0 &&
        this.$store.getters.userInfo.exc != 'qbit'
      )
    },
    // 获取所有的菜单
    async getMenuList() {
      const { data: res } = await this.$http.post('user/menu')
      this.menulist = res.data
      if (this.$store.getters.userInfo.q_type) return null
      this.guideWrapNewShow = res.intro.new
      this.guideWrapOldShow = res.intro.old
      this.guideWrapOldShow1 = res.intro.old
      this.points = res.integral
      this.wjStatus = res.status
      this.isQuestion = res.question

      if (this.guideShowable()) {
        this.guideWrapOldShow = 1
      }

      if (this.guideWrapOldShow == 0) {
        this.tipShow3 = true
      }
      if (this.guideWrapNewShow == 0) {
        this.tipShow1 = true
        this.$router.push('/users')
        localStorage.setItem('isGuideWrapNewShow', 1)
      } else {
        localStorage.removeItem('isGuideWrapNewShow')
      }
    },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.localStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    async isAuthentication() {
      const { data: res } = await this.$http.post('user/get_company')
      if (window.frames.length === parent.frames.length || !localStorage.getItem('auth_token')) {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
      }
    },
    mouseDownHandle() {
      const firstTime = new Date().getTime()
      document.onmouseup = () => {
        // onmouseup 时的时间，并计算差值
        const lastTime = new Date().getTime()
        const totalTime = lastTime - firstTime
        if (totalTime < 200) {
          this.timeFlag = true
        } else {
          this.timeFlag = false
        }
      }
    },
    // 子组件触发父组件的方法
    closeDialog() {
      if (this.driver) {
        this.driver.reset()
      }
    },

    // 问卷弹框
    closeQuestionDialog() {
      this.questionVisible = false
    },

    // 新手引导
    firstStep() {
      this.tipShow1 = false
      this.tipShow2 = true
      this.$router.push('/clientInfo')
    },
    lastFirstStep() {
      this.tipShow1 = true
      this.tipShow2 = false
      this.$router.push('/users')
    },
    async secondStep() {
      this.tipShow2 = false
      const { data: res } = await this.$http.post('cloudfb/intro', {
        type: 1,
      })
      if (res.code == 200) {
        window.location.reload()
      }
    },

    // 已认证用户引导
    thirdStep() {
      this.tipShow3 = false
      this.tipShow4 = true
      this.$router.push('/advertising_list')
    },
    lastThirdStep() {
      this.tipShow3 = true
      this.tipShow4 = false
    },
    async guideDone() {
      this.tipShow4 = false
      const { data: res } = await this.$http.post('cloudfb/intro', {
        type: 2,
      })
      if (res.code == 200) {
        this.guideWrapOldShow = 1
        this.tipShow3 = false
      }
    },
    // 中间全局提示
    async getGlobalPrompt() {
      const { data: res } = await this.$http.post('user/system_message')
      this.globalNotice = res.data
      this.globalPrompt(this.globalNotice)
    },
    globalPrompt(globalNotice) {
      if (globalNotice != '') {
        ElMessage({
          message: `${globalNotice}`,
          type: 'info',
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          offset: 10,
          customClass: 'globalPrompt',
        })
      }
    },
    // 完成新手任务按钮
    goTaskUrl(url, param) {
      if (param == 1) {
        window.localStorage.setItem('taskName', 1)
      } else if (param == 2) {
        window.localStorage.setItem('taskPassword', 1)
      } else if (param == 3) {
        window.localStorage.setItem('taskRoport', 1)
      }

      if (url != '') {
        if (param == 4) {
          setTimeout(() => {
            this.getNewTask()
          }, 5000)
          window.open(url)
        } else {
          window.open(url, '_self')
        }
      } else {
        nextTick(async () => {
          this.$refs.chatBotRef.botShow = true
          // this.botShow = true
          this.$bus.emit('getChatBotSteps')
          // const { data: res } = await this.$http.post('cloudfb/yknow')
          // if (res.code == 200) {
          //   this.getNewTask()
          // }
        })
      }
    },
    async getNewTask() {
      const { data: res } = await this.$http.post('user/novice_task')
      this.newTaskList = res.data
      this.taskNum = res.num
      this.FinishType = res.type
      this.UnfinishedPop()
      this.$nextTick(() => {
        if (this.guideShowable()) {
          this.$refs.dropdownRef.visible = true
        } else {
          if (this.guideWrapOldShow1 == 0) {
            this.guideWrapOldShow = 0
            this.tipShow3 = true
          }
        }
      })

      if (this.FinishType == 1) {
        this.$router.push('/my_advertising')
        this.getMenuList()
        this.$notify({
          title: '恭喜您完成新手任务',
          dangerouslyUseHTMLString: true,
          offset: 100,
          duration: 0,
          type: 'success',
          message: `接下去可去<a href='https://yinocloud.yinolink.com/member' style=" color:#2b82ff;text-decoration: none;">会员中心</a>完成成长任务`,
        })
      }
    },
    // 进入系统时未完成新手任务时的弹框提升
    UnfinishedPop() {
      const isFinished = window.localStorage.getItem('isFinished')
      if (this.guideShowable() && isFinished == null) {
        window.localStorage.setItem('isFinished', 1)
        ElMessageBox.alert('完成新手任务就可以解锁其他功能，同时开放积分系统获得会员等级对应权益哦！', '会员中心上新', {
          confirmButtonText: '确定',
        })
      }
    },
    async upgrade() {
      const { data: res } = await this.$http.post('user/integral_notice')
      if (res.type == 2) {
        this.isUpgrade = 1
        this.upgrade_type = 2
      } else if (res.type == 3) {
        this.isUpgrade = 1
        this.upgrade_type = 3
      } else if (res.type == 4) {
        this.isUpgrade = 1
        this.upgrade_type = 4
      } else if (res.type == 5) {
        this.isUpgrade = 1
        this.upgrade_type = 5
      }
    },
    upgrade_close() {
      this.isUpgrade = 0
    },
    goMember() {
      this.isUpgrade = 0
      this.$router.push('/member')
    },
    chatbotEnter() {
      this.chatbotOpen = 1
    },
    chatbotLeave() {
      this.chatbotOpen = 0
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.home-container {
  height: 100%;
}

.asideLogn {
  position: relative;
  height: 59px;
  background: #fff;
  border-bottom: 1px solid #dcdfe6;
  // border-right: 1px solid #dcdfe6;
  z-index: 6;
}

.el-menu--collapse .el-sub-menu.is-active i {
  color: #2b82ff;
}

.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
  z-index: 6;

  >div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }

  .WJB {
    display: flex;
    align-items: center;
    width: auto;
    height: 20px;
    background-image: linear-gradient(#2b82ff, #4785f2);
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;

    span {
      font-size: 14px;
      color: #fff;
      margin-left: 0;
      margin-right: 8px;
      margin-top: 3px;
    }

    i.iconfont {
      font-size: 18px;
      color: #fff;
    }
  }
}

.el-aside {
  .largeImg {
    width: 200px;
    height: auto;
    margin: 12px 0 0 16px;
    cursor: pointer;
  }

  .miniImg {
    width: 45px;
    height: auto;
    margin: 20px 0 0 16px;
    cursor: pointer;
  }

  // height: 100%;
  background-color: #fff;

  .el-menu {
    border-right: none;
  }

  .el-menu-item:hover {
    background-color: var(--el-background-color-base);
  }

  .iconfont {
    margin-right: 10px;
  }
}

.el-menu-item:hover {
  background-color: var(--el-background-color-base);
}

.el-sub-menu .el-menu-item {
  padding-left: 65px !important;
}

.el-main {
  background-color: #f5f5f7;
}

.el-footer {
  background-color: #f5f5f7;
  padding: 30px 0 20px 0;
  height: auto !important;
  text-align: center;

  .footerLink {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: 0 auto;
    text-align: center;
  }

  p img {
    vertical-align: sub;
  }
}

.iconfont {
  font-size: 24px;
}

#toggle-btn {
  font-size: 30px;
  color: #606266;
  z-index: 999;
  cursor: pointer;
}

#toggle-btn:hover {
  color: #fff;
  background: #2b82ff;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
}

.el-dropdown-menu {
  .iconfont {
    font-size: 20px;
  }

  .el-link {
    display: block;
    width: 100%;
  }
}

.erweimaRow {
  padding: 10px 0;

  i.iconfont {
    font-size: 40px;
    color: #595959;
    cursor: pointer;
  }

  .hoverColor:hover {
    i.iconfont {
      color: #2b82ff;
    }

    span.Text {
      color: #2b82ff;
    }
  }
}

:deep(.el-dialog__header) {
  border: none !important;
}

:deep(.el-dialog__footer) {
  border: none !important;
}

.bindWeChat {
  :deep(.el-dialog__body) {
    padding: 0;
  }
}

// 新手引导
.UserGuideWrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;

  .Guide1 {
    position: absolute;
    top: 75px;
    left: 200px;
  }

  .GuideRange1,
  .GuideRange2,
  .GuideRange3,
  .GuideRange4,
  .GuideRange5 {
    position: absolute;
    top: 180px;
    left: 55px;
    width: 80px;
    height: 35px;
    line-height: 40px;
    text-align: center;
    background: #fff;
    // border: 1px dashed #2b82ff;
  }

  .GuideRange1.unVerify {
    top: 130px !important;
  }

  .GuideRange2 {
    top: 230px;
  }

  .GuideRange2.unVerify {
    top: 180px !important;
  }

  .GuideRange3 {
    width: 80px;
    top: 70px;
    left: 50px;
  }

  .GuideRange4 {
    top: 240px;
    left: 10px !important;
  }

  .GuideRange5 {
    top: auto;
    bottom: 11px;
    width: 220px;
    height: 120px;
    left: 5px;
    background: url(../assets/guide_fastPositon.png) no-repeat;
  }
}

.newTask {
  // width: 100%;
  width: 300px;
  padding: 16px;
  border-top: 1px solid #dcdfe6;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    i {
      font-size: 26px;
      margin-right: 15px;
    }

    span {
      display: inline-block;
      font-size: 14px;
      color: #262626;
    }

    span.taskName {
      width: 150px;
    }

    span.taskCode {
      color: #2b82ff;
      margin-right: 30px;
    }
  }
}

.gradeTag {
  .iconfont {
    font-size: 14px;
    margin-right: 5px;
  }
}

.icon-anchor-full {
  font-size: 14px !important;
}

:deep(.taskDrop .el-dropdown-menu__item) {
  padding: 0;
}

:deep(.el-dropdown-menu__item:not(.is-disabled):hover) {
  background-color: #fff;
}

.pagePop,
.menuPop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.menuPop {
  background: rgba(0, 0, 0, 0);
}

// 快捷入口
.fastMenu {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 190px;
  padding: 0 20px;
  background: #fff;

  .fastTitle {
    display: flex;
    align-items: center;

    .icon_position {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    .title {
      font-size: 14px;
      color: #262626;
    }
  }

  .fastContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;

    >a {
      flex: 50%;
      margin-bottom: 16px;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      .fastIcon {
        display: block;
        width: 28px;
        height: 28px;
      }

      span {
        font-size: 14px;
        color: #262626;
        margin-top: 8px;
        text-align: center;
      }
    }
  }
}

.fastMenuMini {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  padding: 0 20px;
  width: 24px;

  .fastMiniContent {
    li {
      .fastMiniIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.iconfont.icon-ylfankui,
.iconfont.icon-ylxiaoxi,
.iconfont.icon-ylbangzhu {
  color: #595959;
}

.iconfont.icon-ylxiaoxi {
  font-size: 22px;
}

.el-badge {
  margin: 0 30px;
}

// 会员等级升级提示弹窗
.upgrade_warp {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 7;

  .upgrade_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .grade_title {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);

    .grade_name {
      font-size: 24px;
      color: #fff;
    }
  }

  .grade_icon {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);

    .icon_content {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        .iconfont {
          font-size: 30px;
          color: #fff;
          border: 2px solid #fff;
          border-radius: 50%;
          padding: 5px;
        }

        .title {
          font-size: 14px;
          color: #fff;
          margin-top: 4px;
        }
      }
    }

    .specialGrade {
      .title {
        font-size: 16px !important;
      }
    }
  }

  .upgrade_bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 450px;
    background: url('../assets/upgrade_bg.png') no-repeat;
    background-size: cover;
    cursor: pointer;
    z-index: 7;
  }

  .upgrade_gif {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 800px;
    background: url('../assets/update_gif.gif') no-repeat;
    background-size: cover;
    z-index: 6;
  }
}
</style>
